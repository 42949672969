import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="The D.G.B. EP" />

		<h2>The D.G.B. EP</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST04 dgb ep front.jpg"
			alt="Jesse Rivest - The D.G.B. EP - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
		To be released on April 30, 2020.<br />
		The musicbrainz.org edits made on April 14 &amp; 21, 2020, are authorized.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			Some time after moving from New Zealand to Brazil in 2013, I decided to study music at the public School of Music of the Federal District.
			There, I made some new friends to team up with for a project.
			We started with a rendition of At Last (well known via Etta James) and posted that to Youtube.
			We then talked of doing something more; I looked through my repertoire of originals and saw some potential songs that hadn't yet been released as studio recordings.
			After a couple preproduction jams we had some exciting direction to follow for four songs—an EP was underway.
			We recorded the drums and bass together over top of full-band guide tracks that we had made.
			We then recorded the two guitars together over top of the new drums and bass.
			The result is an indie rock/pop sort of production, with melodies and lyrics straddling both rock and alt-country while ultimately not falling far from singer-songwriter pop.
			If that makes any sense?
			And some Brazilian rhythms from maracatu and axé music carry a couple of the songs to places they had never been before.
			The songs themselves, though, are really a product of my time living in New Zealand.
			I hope you enjoy!
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>Thought He'd Ask for a Dollar (4:16) <br /> CA-6P8-20-00001 - © Jesse Rivest 2010</li>
			<li>Same as You (4:32) <br /> CA-6P8-20-00002 - © Jesse Rivest 2010</li>
			<li>The Igloo Song (4:52) <br /> CA-6P8-20-00003 - © Jesse Rivest 2015</li>
			<li>New Trail Old Times (5:16) <br /> CA-6P8-20-00004 - © Jesse Rivest 2010</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST04, © Jesse Rivest 2020, All Rights Reserved<br />
			UPC/EAN: 0193428770843<br />
			Released: April 30, 2020
		</p>

		<h3>Credits</h3>
		<p>
			Produced by Jesse Rivest and Dan Rodrigues.<br />
			Recorded by Jesse Rivest at Studio Quentinet and Over Home Studio; additional guitar and bass reamps recorded by Jesse Rivest with Gustavo Halfeld at Casacájá.<br />
			Mixed and mastered by Jesse Rivest.<br />
			Songs written and sung by Jesse Rivest.<br />
			Lead electric guitars played by Dan Rodrigues.<br />
			Rhythm electric guitars played by Jesse Rivest.<br />
			Bass electric guitar played by Júnior Limeira.<br />
			Drums played by Du Andrade.<br />
			Clapping/palmas in Thought He'd Ask for a Dollar performed by Jesse Rivest, Dan Rodrigues and Du Andrade.<br />
			Vocal chorus in Same as You by Jesse Rivest, Jane Lino, Ayla Gresta, Caroline Bessoni, Dan Rodrigues and Du Andrade.<br />
			Shakers in Thought He'd Ask for a Dollar performed by Jesse Rivest.<br />
			Slide electric guitar in Same as You by Jesse Rivest.<br />
			Cajon (clean plus crunchy snow) in The Igloo Song by Du Andrade.<br />
			Synthesized vibraphone and other sounds in The Igloo Song by Jesse Rivest.<br />
			Album cover artwork by Judy Tailer.<br />
			Album cover layout by Jesse Rivest.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino and to my Mom for being there for/with me through the process.<br />
			Thanks to New Zealand; thanks to Kyle "te Kiwi" Mulinder and whanau, and to all my friends there;
			these songs were written in your wonderful little country, except The Igloo Song, which I finished in Brazil.<br />
			Thanks to Gustavo Halfeld for our many talks about audio engineering.<br />
			Thanks to Misael Barros for his advice and ears.<br />
			Thanks to Samir Júnior at Over Home Studio for his space and time.<br />
			Thanks to Dan, Du and Júnior for patiently persisting this project to its end with me.<br />
		</p>

		<h3>Extras</h3>
		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST04 dgb ep front.jpg"
			alt="Jesse Rivest - Silent - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>
		<h4>One sheet</h4>
		<a href="//www.dropbox.com/s/ab1xqoktz89jy7m/20200512_The_D_G_B_EP_one_sheet.pdf?dl=0">
			<div className="main--centerwrapper">
				<StaticImage
				src="../../images/rasterized documents/DGB EP one sheet.jpg"
				alt="Jesse Rivest - The D.G.B. EP - one sheet"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={200}
				/>
			</div>
		</a>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Thought He'd Ask Me for a Dollar</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2010
			</h5>
			<p>
				Are you going to India? he asked<br />
				I thought he'd ask me for a dollar
			</p>
			<p>
				Standing at the airport in a mismatched suit and tie<br />
				He's not asking for our money; he's not begging for a ride<br />
				He says please can I come with you; would you help me board a plane?<br />
				Would you show me to a hotel room in a country far away?
			</p>
			<p>
				Are you going to Syria? he asked<br />
				I thought he'd ask for their spare dimes
			</p>
			<p>
				He said he's never really travelled—a poor blind man his long life<br />
				Now he's fallen into some money and he has a world of time<br />
				He wants to smell a different ocean; feel the bark of unknown trees<br />
				Get dizzy in foreign tongues and use his own to taste new beers
			</p>
			<p>
				Are you going to Monaco?
			</p>
			<p>
				&lt;instrumental&gt;
			</p>
			<p>
				Are you going to... anywhere's fine with me<br />
				I won't see it but I just wanna be... just wanna be there!
			</p>
			<p>
				I thought he'd ask me for a dollar<br />
				I thought he'd ask for their spare dimes
			</p>
			<p>
				I thought he'd ask me for a dollar<br />
				I said I can't afford my time
			</p>
			<p>
				I thought he'd ask me for a dollar<br />
				I might go back and change my mind
			</p>
			<h4 className="lyrics__title">
				Same as You
			</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2010
			</h5>
			<p>
				I thought of you on your birthday<br />
				You might have turned fifty-six<br />
				On a barstool next to Tom Farmer<br />
				With your eye on Lucy Glide
			</p>
			<p>
				Well you were lonely and abandoned<br />
				A dreamer through and through<br />
				But a homesteader needs a family<br />
				You don't like to sleep alone
			</p>
			<p>
				I was in another long talk<br />
				With the girl I loved to love<br />
				About priorities and commitments<br />
				Oh man, I was letting her down
			</p>
			<p>
				As she got up and left the room<br />
				I saw my stepmom leaving you<br />
				I sat and wondered what it was you did<br />
				Or what it was you couldn't give<br />
				I was scared I'd be the same as you
			</p>
			<p>
				I thought of you on your birthday<br />
				I'm sure my half-brothers did, too<br />
				And after twenty-five years together<br />
				I hear my stepmom resents you
			</p>
			<p>
				I was in another long good-bye<br />
				With the girl I loved to love<br />
				She wanted answers I thought I had<br />
				I wanted freedom most of all
			</p>
			<p>
				As I closed the door behind her<br />
				I saw your family leaving you<br />
				I stood and wondered what it was you did<br />
				Or what it was you couldn't give<br />
				I was scared I'd be the same as you
			</p>
			<p>
				&lt;instrumental&gt;
			</p>
			<p>
				As she got up and left the room<br />
				I saw my stepmom leaving you<br />
				I sat and wondered what it was you did<br />
				Or what it was you couldn't give<br />
				As I closed the door behind her<br />
				I saw your family leaving you<br />
				I stood and wondered what it was you did<br />
				Or what it was you couldn't give<br />
				I was scared I'd be the same as you
			</p>
			<p>
				I was scared I'd be the same as you
			</p>
			<h4 className="lyrics__title">
				The Igloo Song</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2015
			</h5>
			<p>
				You're a mason in this autumn light<br />
				And I've chopped wood to stoke your fire bright<br />
				Putting covers on the bed with hibernation in your head<br />
				And Orion's jewels at your bedside
			</p>
			<p>
				Now come pass these winter days and see<br />
				How a snowman lives eccentrically<br />
				Here's hot whiskey for your toes and your maraschino nose<br />
				We'll hang icicles on our fir trees
			</p>
			<p>
				We'll keep warm here as these blizzards blast<br />
				Though you're used to brick and double glass<br />
				It's a miracle of ice—the muffled crunch of sacrifice<br />
				We'll forget about the world so fast
			</p>
			<p>
				I built this house of ice and snow<br />
				When the birdsong comes I'll have to go<br />
				See these sculptures of our glistening dreams<br />
				Melt away with tears of yesterday
			</p>
			<p>
				I built this house of ice and now<br />
				It's the only way I've ever known<br />
				Now our thawing hopes and crystal dreams<br />
				Are just fleeting streams of yesterday
			</p>
			<p>
				Now you're dusting all the drawers and shelves<br />
				Here's some space if you'll just help yourself<br />
				Pulling covers from the bed with spring cleaning in the head<br />
				And the winds are drifting something else
			</p>
			<p>
				I build this house of ice and snow<br />
				It's the only way I've ever known<br />
				See these sculptures of our glistening dreams<br />
				Melt away with tears of yesterday<br />
				Now our thawing hopes and crystal dreams<br />
				Are just fleeting streams of yesterday
			</p>
			<h4 className="lyrics__title">
				New Trail Old Times</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2010
			</h5>
			<p>
				Stay on the left of the line<br />
				This driving is falling through time<br />
				I've never felt so alone as I do right now
			</p>
			<p>
				A map couldn't say where I'm headed<br />
				But Sexsmith is singing through the stereo<br />
				And something good will come of this sun-flecked West Coast stray
			</p>
			<p>
				I don't wanna ruin your good time<br />
				You don't wanna follow my line<br />
				But we'll go and blaze a new trail like old times
			</p>
			<p>
				&lt;instrumental&gt;
			</p>
			<p>
				I got your text in Franz Josef<br />
				I left my thoughts littered on the roadside<br />
				And every mile ahead's somewhere I've never been
			</p>
			<p>
				Maybe Wanaka's gonna save me<br />
				Maybe you just wanted to float free<br />
				We'll pass each other as we're both going the same way
			</p>
			<p>
				I don't wanna ruin your good time<br />
				You don't wanna follow my line<br />
				But we'll go and blaze a new trail like old times<br />
				I've gotta feeling this will be the last time and<br />
				You and I will see the views from each other's sides<br />
				And we'll go and blaze a new trail like old times
			</p>
			<p>
				&lt;instrumental&gt;
			</p>
			<p>
				I don't wanna ruin your good time<br />
				You don't wanna follow my line<br />
				But we'll go and blaze a new trail like old times<br />
				I've gotta feeling this will be the last time and<br />
				You and I will see the views from each other's sides<br />
				And we'll go and blaze a new trail like old times
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
